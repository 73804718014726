import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class NewsService {
    baseUrl = environment.apiUrl;
    constructor(private http:HttpClient) {}

    getNews$(): Observable<{}> {
        return of({});
    }

    createNew(body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.baseUrl+'/news/store-news',body,{headers:headers});
    }

    getAllNews():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/news',{headers:headers});
    }

    getOneNew(id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/news/'+id,{headers:headers});
    }

    updateNew(id:number, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.put(this.baseUrl+'/news/'+id,body,{headers:headers});
    }

    deleteNew(id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.delete(this.baseUrl+'/news/'+id,{headers:headers});
    }
}
