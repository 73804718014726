import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { User } from '../models';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { signUp } from '../containers/register-data/model';
import { Router } from '@angular/router';

const userSubject: ReplaySubject<User> = new ReplaySubject(1);

@Injectable()
export class UserService {
    baseUrl: string = environment.apiUrl;
    constructor( private _http:HttpClient, private router:Router) {
        let identity = JSON.parse(<string>localStorage.getItem('identity'));
        if(identity){
            this.user = {
                token: identity.token,
                firstName: identity.name,
                lastName: identity.surname,
                email: identity.email,
                image: '/assets/img/illustrations/profiles/profile-1.png',
            }
        }
    }

    set user(user: User) {
        userSubject.next(user);
    }

    get user$(): Observable<User> {
        return userSubject.asObservable();
    }

    registerUser(body:signUp):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.baseUrl+'/auth/signUp',body,{
            headers:headers,
        })
    }

    login(body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.baseUrl+'/auth/login',body,{
            headers:headers,
        })
    }

    logout(){
        localStorage.removeItem('identity');
        this.router.navigate(['']);
    }

    forgotPassword(email: any): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.baseUrl+'/auth/recover-password', email,{headers:headers});
    }

    recoverPassword(body:any, token: any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.baseUrl+'/auth/reset-password', body, {headers:headers, params:token});
    }

    createBusiness(body:any, user_id:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.baseUrl+'/shop/store', body,{headers:headers, params:{user_id: user_id}})
    }

    getAllUsers(): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.get(this.baseUrl+'/user',{headers:headers});
    }

    getOneUser(userId:any):Observable<any>{
        return this._http.get(this.baseUrl+'/user/'+userId);
    }

    editUser(userId:any, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.put(this.baseUrl+'/user/'+ userId,body,{headers:headers});
    }

    createAdminUser(body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.post(this.baseUrl+'/user/admin', body,{headers:headers});
    }

    emailVerify(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.post(this.baseUrl+'/auth/verify',{},{headers:headers, params:token});
    }

    deleteUser(user_id:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.delete(this.baseUrl+'/user/' + user_id,{headers:headers});
    }
}
