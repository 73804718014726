import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutModalComponent } from '@modules/app-common/components';
import { UserService } from '@modules/auth/services';

@Component({
    selector: 'sbpro-top-nav-user',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav-user.component.html',
    styleUrls: ['top-nav-user.component.scss'],
})
export class TopNavUserComponent implements OnInit {
    identity = JSON.parse(<string>localStorage.getItem('identity'));
    @Input() placement = 'bottom-end';
    @ViewChild('logoutModal') logoutModal!:LogoutModalComponent;
    dropdownClasses: string[] = [];
    constructor(public userService: UserService, private router:Router) {}
    ngOnInit() {}

    logOut(){
        this.logoutModal.openModal();
    }
}
