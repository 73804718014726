import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
    selector: 'sb-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() dark = false;
    constructor(private router:Router) {}
    ngOnInit() {}

    goToRegister(){
        this.router.navigate(['/auth/register-cuit']);
    }
}
