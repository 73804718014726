<ng-template #logout let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            Atención
        </h5>
        <button class="btn-close" type="button" aria-label="Close" (click)='modal.dismiss("Cross click")'><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        ¿Estás seguro de cerrar sesión?
    </div>
    <div class="modal-footer"><button class="btn btn-outline-primary" type="button" (click)="modal.close()">Cancelar</button><button class="btn btn-primary" type="button" (click)="modal.close('exit')">Sí, salir</button></div>
</ng-template>