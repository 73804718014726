import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class BenefitsService {
    baseUrl = environment.apiUrl;
    constructor(private _http:HttpClient) {}

    getBenefits$(): Observable<{}> {
        return of({});
    }

    getBenefits(): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/benefits/',{headers:headers});
    }

    getBenefit(user_id:any, benefit_id?:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        if(benefit_id){
            return this._http.get(this.baseUrl+'/benefits/'+user_id,{headers:headers, params:{benefit_id:benefit_id}});
        } else {
            return this._http.get(this.baseUrl+'/benefits/'+user_id,{headers:headers});
        }
        
    }

    updateBenefit(benefit_id:any, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.put(this.baseUrl+'/benefits/'+benefit_id,body,{headers:headers});
    }

    getBenefitType():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/benefits_types',{headers:headers});
    }

    getBenefitsStates():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/benefits_states',{headers:headers});
    }

    createBenefit(body:any, user_id:any){
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.post(this.baseUrl+'/benefits/store/',body,{headers:headers, params:{user_id:user_id}});
    }

    deleteBenefit(benefit_id:any){
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.delete(this.baseUrl+'/benefits/'+benefit_id,{headers:headers});
    }
    
    editBenefit(benefit_id:number, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.put(this.baseUrl+'/benefits/'+benefit_id,body,{headers:headers});
    }

    decreaseQuota(benefit_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/benefits/quota/'+benefit_id,{headers:headers});
    }
}
