import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sbpro-card-header-ebcm',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './card-header-ebcm.component.html',
    styleUrls: ['card-header-ebcm.component.scss'],
})
export class CardHeaderEbcmComponent implements OnInit {
    @Input() isModal: boolean = false;
    constructor() {}
    ngOnInit() {}
}
