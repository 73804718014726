import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class BusinessService {

    baseUrl = environment.apiUrl;
    constructor(private _http:HttpClient) {}

    getBusiness(user_id:any): Observable<any> {
        return this._http.get(this.baseUrl+'/shop/'+user_id);
    }

    getHeadings():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/heading',{headers:headers});
    }
}
