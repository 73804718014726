import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class AdminTotalGuard implements CanActivate {

    constructor(private router:Router){}
    canActivate(): Observable<boolean> {
        let identity = JSON.parse(<string>localStorage.getItem('identity'));
        let role = identity.role_id;
        if(role == 3){
            return of (true);
        } else {
            this.router.navigate(['/dashboard/admin']);
            return of(false);
        }
    }
}