import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'sb-top-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './top-nav.component.html',
    styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    @Input() rtl = false;
    @Input() isLanding: boolean = false;
    @Output() loginClicked: EventEmitter<any> = new EventEmitter();
    identity:any;
    constructor(private navigationService: NavigationService, private router:Router) {
        this.identity = JSON.parse(<string>localStorage.getItem('identity'));
    }
    ngOnInit() {}
    toggleSideNav() {
        this.navigationService.toggleSideNav();
    }

    openLogin(){
        this.loginClicked.emit();
    }

    redirect(){
        this.identity.role_id == '1'? this.router.navigate(['/dashboard/users/user-pre-home']) : this.router.navigate(['/dashboard/admin']);
    }
}
