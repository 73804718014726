import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class InscriptionService {
    baseUrl = environment.apiUrl;
    constructor(private http:HttpClient) {}

    getInscription$(): Observable<{}> {
        return of({});
    }

    getShopsOfBenefit(user_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/inscriptions/'+user_id,{headers:headers});
    }

    subscribeToBenefit(user_id:number, benefit_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.baseUrl+'/inscriptions/store/'+benefit_id+'/'+user_id,{headers:headers});
    }

    getAllInscriptions():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/inscriptions',{headers:headers});
    }

    checkInscription(user_id:number, benefit_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/inscriptions/'+user_id+'/'+benefit_id,{headers:headers});
    }

    updateState(benefit_id:number , body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.put(this.baseUrl+'/inscriptions/'+benefit_id,body,{headers:headers});
    }

    getUsersOfBenefit(benefit_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/inscriptions/'+benefit_id+'/users-to-benefit',{headers:headers});
    }
}
