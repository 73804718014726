import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dashboard-abm',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dashboard-abm.component.html',
    styleUrls: ['dashboard-abm.component.scss'],
})
export class DashboardAbmComponent implements OnInit {
    @Input() icon!: string;
    @Input() title!: string;
    @Input() description!: string;
    @Input() breadcrumbs = false;
    @Input() simplified = false;
    @Input() light = false;
    @Input() showDateRange!: false;
    @Input() showSearch!: false;

    darkClasses = ['page-header-dark', 'bg-gradient-primary-to-secondary'];
    lightClasses = ['page-header-light', 'bg-white', 'shadow'];

    constructor() {}
    ngOnInit() {}

    goBack(){
        window.history.back();
    }
}
