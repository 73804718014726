import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sbpro-logout-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './logout-modal.component.html',
    styleUrls: ['logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
    @ViewChild('logout') logout!:TemplateRef<unknown>;

    constructor(private modalService: NgbModal, private userService:UserService) {}
    ngOnInit() {}

    openModal(){
        this.modalService.open(this.logout).result.then(result=>{
            result == 'exit' && (this.modalService.dismissAll(), 'exit' && this.userService.logout());
        })
    }
}
