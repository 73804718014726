import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class ContentsService {

    baseUrl = environment.apiUrl;
    constructor(private http:HttpClient) {}

    getContents$(): Observable<{}> {
        return of({});
    }

    saveImg(body: any, benefit_id:any):Observable<any>{
        return this.http.post(this.baseUrl+'/contents/save-img/'+benefit_id,body);
    }
}
