import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class ShopsService {
    baseUrl = environment.apiUrl;
    constructor(private http:HttpClient) {}

    getShops$(): Observable<{}> {
        return of({});
    }

    getShopsByUserId(user_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.get(this.baseUrl+'/shop/'+user_id,{headers:headers});
    }

    editShop(shop_id:number, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.put(this.baseUrl+'/shop/'+shop_id,body,{headers:headers});
    }

    createShop(user_id:number, body:any){
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.post(this.baseUrl+'/shop/store',body,{headers:headers, params:{user_id:user_id}});
    }

    deleteShop(shop_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this.http.delete(this.baseUrl+'/shop/'+shop_id,{headers:headers});
    }
}
