import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class EntityMailsService {
    baseUrl = environment.apiUrl;
    constructor(private _http:HttpClient) {}

    getEntityMails$(): Observable<{}> {
        return of({});
    }

    getEntityMails(benefit_id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.get(this.baseUrl+'/entities_emails/'+benefit_id,{headers:headers});
    }

    editEntityMails(id:any ,body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.put(this.baseUrl+'/entities_emails/'+id,body,{headers:headers});
    }

    deleteEntityMail(id:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.delete(this.baseUrl+'/entities_emails/'+id,{headers:headers});
    }

    createEntityMail(benefit_id: any, body:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        return this._http.post(this.baseUrl+'/entities_emails/'+benefit_id,body,{headers:headers});
    }
}
