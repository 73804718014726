<div class="d-grid">
    <footer class="footer-admin mt-auto f-bg" [ngClass]='{"footer-light": !dark, "footer-dark": dark}'>
        <div class="px-4">
            <div class="row gx-5 gy-5 justify-content-center">
                <div id="second" class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <img class="ebcm-logo text-center text-white img-fluid"src="../../../../assets/img/ebcm-logo-white.svg" >
                </div>
                <div id="third" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 small text-white">
                    <div id="third-child">
                        <div>
                            Contacto
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i-feather class="icons" name="mail"></i-feather><div class="custom-span"><span>direccionindcom@sanjuan.gov.ar</span></div>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i-feather class="icons" name="phone"></i-feather><div class="custom-span"><span>+5492644592201</span></div>
                        </div>
                    </div>
                </div>
                <div id="fourth" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 small text-white">
                    <div id="fourth-child">
                        <div>Redes</div>
                        <div class="d-flex align-items-center mt-2">
                            <a href="https://instagram.com/elegibiencompramejor?igshid=YmMyMTA2M2Y=" target="blank">
                                <i-feather class="icons" name="instagram"></i-feather>
                            </a><a href="https://www.facebook.com/elegibiencompramejor" target="blank">
                                <i-feather class="icons" name="facebook"></i-feather>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="first" class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <button class="btn btn-primary" (click)="goToRegister()">Registrarme</button>
                </div>
            </div>
        </div>
    </footer>
</div>
