<li class="nav-item dropdown no-caret me-3 me-lg-0 dropdown-user" *ngIf="identity" ngbDropdown [placement]="placement" display="dynamic">
    <div class="dropdown-toggle" id="navbarDropdownUserImage" ngbDropdownToggle data-cy="userMenu" role="button" aria-haspopup="true" aria-expanded="false">
        <a class="btn btn-icon btn-transparent-dark " ><img class="img-fluid" src="../../../../assets/img/illustrations/profiles/profile-1.png" /></a><span class="mx-2 text-white">{{identity.name}}&nbsp;{{identity.surname}}</span>
    </div>
    <div class="dropdown-menu border-0 shadow animated--fade-in-up" ngbDropdownMenu aria-labelledby="dropdownUser">
        <h6 class="dropdown-header d-flex align-items-center">
            <img class="dropdown-user-img" src="../../../../assets/img/illustrations/profiles/profile-1.png" />
            <div class="dropdown-user-details">
                <div class="dropdown-user-details-name">{{ identity.name }} {{ identity.surname }}</div>
                <div class="dropdown-user-details-email">{{ identity.email }}</div>
            </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" routerLink="/dashboard/user-edit/{{identity.id}}"
            ><div class="dropdown-item-icon"><i-feather name="settings"></i-feather></div>
            Mi cuenta</a
        ><a class="dropdown-item" (click)="logOut()"
            ><div class="dropdown-item-icon"><i-feather name="log-out"></i-feather></div>
            Cerrar sesión</a
        >
    </div>
</li>
<sbpro-logout-modal #logoutModal></sbpro-logout-modal>
